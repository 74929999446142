import {
	FirmwareUpdateProgress,
	FirmwareUpdateStage,
	FirmwareUpdateStatus,
	InventoryDevice,
	MissingFeatureType,
	ProxiedTransmitterForInventory,
	ProxiedTransmittersForInventory
} from '@shure/cloud/shared/models/devices';
import { mapDurationFromSysApi } from '@shure/shared/angular/data-access/system-api/mappers';

import { DeviceStatusService } from '../../api/device-status.service';
import { InventoryDeviceFragment, ProxiedTransmitterStatusFragment } from '../graphql/generated/cloud-sys-api';

import { mapBatteryStateFromSysApi } from './map-battery-state';
import { mapFirmwarePackagesFromSysApi } from './map-firmware-packages';
import { mapMicStatusFromSysApi } from './map-mic-status';
import { mapDeviceModelFromSysApi, mapDeviceStateFromSysApi, mapFeatureValueFromSysApi } from './utils';

// temp function for testing, and until we have the schema and service in place.
let tagIndex = -1;
function getTags(): string[] {
	const tempTags: string[][] = [
		[],
		['TempTag 1'],
		['TempTag 2', 'TempTag 3'],
		['TempTag 4', 'TempTag 5', 'TempTag 6'],
		['TempTag 7', 'TempTag 8', 'TempTag 9']
	];
	tagIndex = (tagIndex + 1) % tempTags.length;
	return tempTags[tagIndex];
}

export function mapInventoryDeviceFromSysApi(
	device: InventoryDeviceFragment,
	statusService: DeviceStatusService
): InventoryDevice {
	const name = mapFeatureValueFromSysApi(device.features.name?.name);

	const batteryLevel = {
		percentage: device.features.batteryLevel?.percentage ?? 0,
		currentState: mapBatteryStateFromSysApi(device.features.batteryLevel?.status.currentState),
		targetState: mapBatteryStateFromSysApi(device.features.batteryLevel?.status.targetState),
		timeToTargetState: mapDurationFromSysApi(device.features.batteryLevel?.status.timeToTargetState),
		isMissing: !device.description.features.batteryLevel?.supported
	};

	const controlNetwork = {
		ipAddress: device.features.controlNetwork
			? mapFeatureValueFromSysApi(device.features.controlNetwork.interface.ipAddress)
			: mapFeatureValueFromSysApi(device.protocol.dmp?.ipAddress),
		macAddress: mapFeatureValueFromSysApi(device.features.controlNetwork?.interface.macAddress),
		isMissing: !device.description.features.controlNetwork.supported
	};

	const firmware = {
		version: mapFeatureValueFromSysApi(device.features.firmware?.version),
		valid: device.features.firmware?.valid || true,
		isUpToDate: true,
		isMissing: !device.description.features.firmware.supported
	};

	const identify = {
		identifying: device.features.identify?.identifying || false,
		isMissing: !device.description.features.identify.supported
	};

	const micStatus = {
		status: mapMicStatusFromSysApi(device.features.micStatus?.status),
		isMissing: !device.description.features.micStatus?.supported
	};

	const serialNumber = {
		serialNumber: mapFeatureValueFromSysApi(device.features.serialNumber?.serialNumber),
		isMissing: !device.description.features.serialNumber.supported
	};

	const updateProgress: FirmwareUpdateProgress & MissingFeatureType = {
		package: {
			key: device.features.updateProgress?.firmwarePackageKey ?? '',
			version: device.features.updateProgress?.firmwarePackageVersion ?? '0.0.0.0'
		},
		percentComplete: device.features.updateProgress?.deviceProgressPercentage ?? 100,
		stage: device.features.updateProgress?.updateStage ?? FirmwareUpdateStage.Idle,
		status: device.features.updateProgress?.updateStatus ?? FirmwareUpdateStatus.Success,
		isMissing: !device.description.features.updateProgress.supported
	};

	const doublestuffProxiedTransmitters: ProxiedTransmittersForInventory & MissingFeatureType = {
		...mapProxiedTransmitters(device),
		isMissing: !device.description.features.doublestuffProxiedTransmitters?.supported
	};

	const availablePackages = {
		primaryPackages: mapFirmwarePackagesFromSysApi(device.features.availablePackages),
		isMissing: !device.description.features.availablePackages.supported
	};

	const proxiedDevices = {
		proxiedDevices: (device.features.proxiedDevices?.proxiedDevices ?? []).map((proxiedDevice) => proxiedDevice),
		isMissing: !device.description.features.proxiedDevices?.supported
	};

	const room = {
		room: {
			name: mapFeatureValueFromSysApi(device.features.room?.room?.name)
		},
		isMissing: !device.description.features.room.supported
	};

	const tags = {
		tags: getTags(),
		isMissing: false
	};

	const unaugmentedDevice: Omit<InventoryDevice, 'conditions'> = {
		id: device.id,
		name,
		model: mapDeviceModelFromSysApi(device.interface.model ?? ''),
		virtual: device.virtual,
		deviceState: mapDeviceStateFromSysApi(device.status),
		features: {
			availablePackages,
			batteryLevel,
			controlNetwork,
			doublestuffProxiedTransmitters,
			firmware,
			identify,
			micStatus,
			proxiedDevices,
			room,
			serialNumber,
			updateProgress,
			tags
		}
	};

	return {
		...unaugmentedDevice,
		conditions: statusService.conditionsFor(<InventoryDevice>unaugmentedDevice)
	};
}

function mapProxiedTransmitters(device: InventoryDeviceFragment): ProxiedTransmittersForInventory {
	return {
		mic1: mapProxiedTransmitter(device.features.doublestuffProxiedTransmitters?.mic1),
		mic2: mapProxiedTransmitter(device.features.doublestuffProxiedTransmitters?.mic2)
	};
}

function mapProxiedTransmitter(
	transmitter?: ProxiedTransmitterStatusFragment | null
): ProxiedTransmitterForInventory | null {
	if (!transmitter) {
		return null;
	}

	const batteryLevel = (transmitter.batteryLevelPercentage ?? Number.NaN) / 100;

	return {
		batteryLevel
	};
}
