import assertNever from 'assert-never';
import { Duration } from 'ts-luxon';

import { IpMode, DeviceState, RfChannel } from '@shure/cloud/shared/models/devices';
import { DeviceStatus } from '@shure/shared/angular/data-access/system-api/core';
import { mapBatteryStateFromSysApi, mapDurationFromSysApi } from '@shure/shared/angular/data-access/system-api/mappers';
import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

import { PropertyPanelRfChannelFragment, IpMode as SysApiMode } from '../graphql/generated/cloud-sys-api';

import { mapMicStatusFromSysApi } from './map-mic-status';
// wangth: The usage of this value should be removed, and replaced with test for isMissing attribute,
// which should go all the way to UI.
export const MISSING_VALUE = '..';

export function mapFeatureValueFromSysApi(value?: string | null): string {
	return value ?? MISSING_VALUE;
}

export function mapIpModeFromSysApi(value: SysApiMode | undefined): IpMode | undefined {
	if (value === undefined) {
		return undefined;
	}
	switch (value) {
		case SysApiMode.Dhcp:
			return IpMode.DHCP;
		case SysApiMode.Static:
			return IpMode.STATIC;
		default:
			assertNever(value);
	}
}

/**
 * TODO: Remove this mapping and use the value directly from GraphQL. We want to make this change
 * as part of a larger refactoring to create a default DeviceImageProvider, which will allow us
 * to remove all of the individual device behavior projects:
 * https://bitbucket.shure.com/projects/WPLT/repos/shure.web.apps/pull-requests/3859/overview?commentId=176994
 */
export function mapDeviceModelFromSysApi(model: string): DeviceModel {
	switch (model) {
		case 'MXA920-S':
			return DeviceModel.MXA920S;
		case 'MXA920-R':
			return DeviceModel.MXA920R;
		case 'P300':
			return DeviceModel.P300;
		case 'MXWAPXD2':
			return DeviceModel.MXWAPXD2;
		case 'MXA710-4FT':
			return DeviceModel.MXA7104FT;
		case 'MXA710-2FT':
			return DeviceModel.MXA7102FT;
		case 'ANIUSB-MATRIX':
			return DeviceModel.ANIUSB;
		case 'MXA901-R':
			return DeviceModel.MXA901R;
		case 'MXA901-S':
			return DeviceModel.MXA901S;
		case 'MXA902-S':
			return DeviceModel.MXA902S;
		case 'MXW1X':
			return DeviceModel.MXW1X;
		case 'MXW2X':
			return DeviceModel.MXW2X;
		case 'MXW6X':
			return DeviceModel.MXW6X;
		case 'MXW8X':
			return DeviceModel.MXW8X;
		case 'IMX-Room':
			return DeviceModel.IMXROOM;
		case 'MXN5-C':
			return DeviceModel.MXN5C;
		case 'ANX4':
			return DeviceModel.MCR;
		case 'AD600':
			return DeviceModel.AD600;
		case 'ADTD':
			return DeviceModel.ADTD;
		case 'ADTQ':
			return DeviceModel.ADTQ;
		default:
			return <DeviceModel>model;
	}
}

export function mapDeviceStateFromSysApi(deviceStatus: DeviceStatus): DeviceState {
	switch (deviceStatus) {
		case DeviceStatus.Discovered:
			return DeviceState.Discovered;
		case DeviceStatus.Error:
			return DeviceState.Error;
		case DeviceStatus.Offline:
			return DeviceState.Offline;
		case DeviceStatus.Online:
			return DeviceState.Online;
		default:
			return DeviceState.Error;
	}
}

export function mapUptimeDurationFromSysApi(duration: string | undefined): string {
	if (!duration || duration === MISSING_VALUE) return '';

	// Converts ISO 8601 time duration to [h]:mm:ss
	return Duration.fromISO(duration).toFormat('hh:mm:ss');
}

export function mapRfChannelsFromSysApi(rfChannels: PropertyPanelRfChannelFragment[]): RfChannel[] {
	const channels: RfChannel[] = [];

	for (const rfChannel of rfChannels) {
		const linkedDevice = rfChannel.features.linkedTransmitter?.linkage?.device;
		if (!linkedDevice) {
			channels.push({});
			continue;
		}

		const descriptionFeatures = linkedDevice.description.features;
		const features = linkedDevice.features;
		channels.push({
			linkedDevice: {
				id: linkedDevice.id,
				name: mapFeatureValueFromSysApi(features.name?.name ?? ''),
				status: mapDeviceStateFromSysApi(linkedDevice.status),
				model: mapDeviceModelFromSysApi(linkedDevice.interface.model ?? ''),
				features: {
					mute: {
						muted: features.audioMute?.muted || false,
						isMissing: !descriptionFeatures.audioMute.supported
					},
					identify: {
						identifying: features.identify?.identifying || false,
						isMissing: !descriptionFeatures.identify.supported
					},
					micStatus: {
						status: mapMicStatusFromSysApi(features.micStatus?.status),
						isMissing: !descriptionFeatures.micStatus.supported
					},
					batteryLevel: {
						percentage: features.batteryLevel?.percentage ?? Number.NaN,
						currentState: mapBatteryStateFromSysApi(features.batteryLevel?.status.currentState),
						targetState: mapBatteryStateFromSysApi(features.batteryLevel?.status.targetState),
						timeToTargetState: mapDurationFromSysApi(features.batteryLevel?.status.timeToTargetState),
						isMissing: !descriptionFeatures.batteryLevel.supported
					}
				}
			}
		});
	}

	return channels;
}
