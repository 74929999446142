import { PercentPipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import {
	DeviceConditionSeverity,
	DeviceState,
	InventoryDevice,
	ProxiedTransmitterForInventory,
	SortedDeviceConditionCollection
} from '@shure/cloud/shared/models/devices';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ILogger } from '@shure/shared/angular/utils/logging';

@Injectable()
export class DeviceStatusService {
	public static readonly lowBatteryThreshold = 0.05; // 5%
	public static readonly veryLowBatteryThreshold = 0.02; // 2%

	private logger: ILogger;

	constructor(
		logger: ILogger,
		private translocoService: TranslocoService,
		private percentPipe: PercentPipe,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment
	) {
		this.logger = logger.createScopedLogger('DeviceStatusService');
	}

	public conditionsFor(device: InventoryDevice): SortedDeviceConditionCollection {
		return this.appEnv.showProxiedDevices
			? this.getConditionsFromBatteryLevel(device)
			: this.getConditionsFromDoublestuffProxiedTransmitters(device);
	}

	private getConditionsFromDoublestuffProxiedTransmitters(device: InventoryDevice): SortedDeviceConditionCollection {
		const conditionCollection = new SortedDeviceConditionCollection();

		const proxiedTransmitters = [
			device.features.doublestuffProxiedTransmitters?.mic1,
			device.features.doublestuffProxiedTransmitters?.mic2
		];

		proxiedTransmitters.forEach((mic) => {
			if (this.checkBatteryLevelAtOrBelow(DeviceStatusService.veryLowBatteryThreshold, mic)) {
				conditionCollection.add({
					severity: DeviceConditionSeverity.High,
					description: this.translocoService.translate('mic-battery-very-low', {
						percent: this.formatDoublestuffBatteryLevel(mic)
					})
				});
			} else if (this.checkBatteryLevelAtOrBelow(DeviceStatusService.lowBatteryThreshold, mic)) {
				conditionCollection.add({
					severity: DeviceConditionSeverity.Moderate,
					description: this.translocoService.translate('mic-battery-low', {
						percent: this.formatDoublestuffBatteryLevel(mic)
					})
				});
			}
		});

		this.logger.trace(
			'conditionsFor',
			device.name,
			JSON.stringify({
				conditions: conditionCollection.conditions,
				proxiedTransmitters: device.features.doublestuffProxiedTransmitters
			})
		);
		return conditionCollection;
	}

	private checkBatteryLevelAtOrBelow(threshold: number, mic?: ProxiedTransmitterForInventory | void): boolean {
		const currentBatteryLevel = mic?.batteryLevel ?? Number.NaN;
		return currentBatteryLevel <= threshold;
	}

	private formatDoublestuffBatteryLevel(mic?: ProxiedTransmitterForInventory): string {
		if (mic?.batteryLevel === undefined) {
			return '';
		}

		return this.percentPipe.transform(mic?.batteryLevel) ?? '';
	}

	private getConditionsFromBatteryLevel(device: InventoryDevice): SortedDeviceConditionCollection {
		const conditionCollection = new SortedDeviceConditionCollection();

		if (device.deviceState !== DeviceState.Online) {
			return conditionCollection;
		}
		if (!device.features.batteryLevel?.isMissing) {
			const batteryLevel = device.features.batteryLevel?.percentage ?? Number.NaN;
			if (batteryLevel <= DeviceStatusService.veryLowBatteryThreshold) {
				conditionCollection.add({
					severity: DeviceConditionSeverity.High,
					description: this.translocoService.translate(
						'cloud.device-management.inventory.mic-battery-very-low',
						{
							percent: this.formatBatteryLevel(batteryLevel)
						}
					)
				});
			} else if (batteryLevel <= DeviceStatusService.lowBatteryThreshold) {
				conditionCollection.add({
					severity: DeviceConditionSeverity.Moderate,
					description: this.translocoService.translate('cloud.device-management.inventory.mic-battery-low', {
						percent: this.formatBatteryLevel(batteryLevel)
					})
				});
			}
		}

		this.logger.trace(
			'conditionsFor',
			device.name,
			JSON.stringify({
				conditions: conditionCollection.conditions,
				proxiedTransmitters: device.features.doublestuffProxiedTransmitters
			})
		);
		return conditionCollection;
	}

	private formatBatteryLevel(batteryLevel: number): string {
		return Number.isNaN(batteryLevel) ? '--' : `${batteryLevel}%`;
	}
}
