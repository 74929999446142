export enum UpdateState {
	Updating = 'Updating',
	Done = 'Done',
	Error = 'ERROR'
}

export interface UpdateResponse<DoneType, ErrorType> {
	state: UpdateState;
	done?: DoneType;
	error?: ErrorType;
}

export interface UpdateRequestResponse<ErrorType> {
	state: UpdateState;
	error?: ErrorType;
}

export enum CreateState {
	Creating = 'Creating',
	Done = 'Done',
	Error = 'ERROR'
}

export interface CreateResponse<DoneType, ErrorType> {
	state: CreateState;
	done?: DoneType;
	error?: ErrorType;
}

export enum DeleteState {
	Deleting = 'Deleting',
	Done = 'Done',
	Error = 'ERROR'
}

export interface DeleteResponse<DoneType, ErrorType> {
	state: DeleteState;
	done?: DoneType;
	error?: ErrorType;
}
