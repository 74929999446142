import { ProxiedTransmitter, ProxiedTransmitterStatus } from '@shure/cloud/shared/models/devices';
import { DoubleStuffProxiedTransmitterStatus } from '@shure/shared/angular/data-access/system-api/core';

import { DoublestuffProxiedTransmitterFragment } from '../graphql/generated/cloud-sys-api';

export function mapProxiedTransmitterFromSysApi(
	transmitter?: DoublestuffProxiedTransmitterFragment | null
): ProxiedTransmitter {
	return {
		status: mapProxiedTransmitterStatusFromSysApi(transmitter?.status),
		name: transmitter?.name,
		online: transmitter?.online,
		batteryLevel: mapProxiedTransmitterBatteryLevelFromSysApi(transmitter?.batteryLevelPercentage),
		muted: transmitter?.muted ?? undefined
	};
}

function mapProxiedTransmitterStatusFromSysApi(
	transmitterStatus?: DoubleStuffProxiedTransmitterStatus | null
): ProxiedTransmitterStatus {
	switch (transmitterStatus) {
		case 'ACTIVE':
			return ProxiedTransmitterStatus.Active;
		case 'CHARGING':
			return ProxiedTransmitterStatus.Charging;
		default:
			return ProxiedTransmitterStatus.Unknown;
	}
}

function mapProxiedTransmitterBatteryLevelFromSysApi(batteryLevel?: number | null): number | undefined {
	if (batteryLevel === undefined || batteryLevel === null) {
		return undefined;
	}

	return batteryLevel / 100;
}
