import { Injectable, signal } from '@angular/core';
import { Observable, map, catchError, of } from 'rxjs';

import { UpdateRequestResponse, UpdateResponse, UpdateState } from '@shure/cloud/shared/models/http';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { CloudDeviceApiService } from '../api/cloud-device-api.service';

import {
	DeviceRebootMutationGQL,
	DeviceStartIdentifyMutationGQL,
	DeviceStopIdentifyMutationGQL,
	DeviceUpdateFirmwareMutationGQL,
	DeviceUpdateMutationGQL,
	FirmwareUpdateRequestState,
	UpdateDeviceFirmwareInput
} from './graphql/generated/cloud-sys-api';

@Injectable({ providedIn: 'root' })
export class SysApiCloudDeviceApiService extends CloudDeviceApiService {
	private readonly logger: ILogger;
	public override readonly lastFWUpdateTime = signal(0);

	constructor(
		logger: ILogger,
		private readonly deviceStopIdentifyMutationGQL: DeviceStopIdentifyMutationGQL,
		private readonly deviceStartIdentifyMutationGQL: DeviceStartIdentifyMutationGQL,
		private readonly deviceRebootMutationGQL: DeviceRebootMutationGQL,
		private readonly deviceUpdateMutationGQL: DeviceUpdateMutationGQL,
		private readonly deviceUpdateFirmwareMutationGQL: DeviceUpdateFirmwareMutationGQL
	) {
		super();

		this.logger = logger.createScopedLogger('DaiCloudDeviceService');
	}

	/**
	 * Toggle identify for a device.
	 * @param deviceId
	 * @param identify
	 * @returns
	 */
	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateRequestResponse<string>> {
		this.logger.trace('toggleIdentify', 'Toggling identify', { deviceId, identify });
		const mutation$: Observable<unknown> = identify
			? this.deviceStartIdentifyMutationGQL.mutate({ startIdentifyId: deviceId })
			: this.deviceStopIdentifyMutationGQL.mutate({ stopIdentifyId: deviceId });

		return mutation$.pipe(
			map(() => {
				this.logger.trace('toggleIdentify', 'Done', { deviceId });
				return { state: UpdateState.Done };
			}),
			catchError((error: Error) => {
				this.logger.error('toggleIdentify', 'Error', { error });
				return of({ state: UpdateState.Error, error: error.message });
			})
		);
	}

	/**
	 * Set mute for a device.
	 * @param deviceId
	 * @param deviceMute
	 * @returns
	 */
	public setMute(deviceId: string, deviceMute: boolean): Observable<UpdateRequestResponse<string>> {
		this.logger.trace('setMute', 'Setting mute', { deviceId, deviceMute });
		return this.deviceUpdateMutationGQL
			.mutate({
				updates: [
					{
						device: {
							features: {
								audioMute: {
									muted: deviceMute
								}
							},
							id: deviceId
						}
					}
				]
			})
			.pipe(
				map((result) => {
					if (
						result.data?.updateNodes &&
						'error' in result.data.updateNodes[0] &&
						result.data.updateNodes[0].error !== null
					) {
						this.logger.error('setMute', 'Error', {
							deviceId,
							result
						});
						return { state: UpdateState.Error, error: result.data.updateNodes[0].error?.message };
					}
					this.logger.trace('setMute', 'Done', { deviceId });
					return { state: UpdateState.Done };
				}),
				catchError((error: Error) => {
					this.logger.error('setMute', 'Error', { error });
					return of({ state: UpdateState.Error, error: error.message });
				})
			);
	}

	public setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setName()', 'Setting name', { deviceId, name });
		return this.deviceUpdateMutationGQL
			.mutate({
				updates: [
					{
						device: {
							features: {
								name: {
									name
								}
							},
							id: deviceId
						}
					}
				]
			})
			.pipe(
				map((result) => {
					if (
						result.data?.updateNodes &&
						'error' in result.data.updateNodes[0] &&
						result.data.updateNodes[0].error !== null
					) {
						this.logger.error('setName', 'Error', {
							deviceId,
							result
						});
						return { state: UpdateState.Error, error: result.data.updateNodes[0].error?.message };
					}
					this.logger.trace('setName()', 'Done', { deviceId });
					return { state: UpdateState.Done };
				}),
				catchError((error: Error) => {
					this.logger.error('setName()', 'Error', { error });
					return of({ state: UpdateState.Error, error: error.message });
				})
			);
	}

	/**
	 * Reboot a device
	 * @param deviceId
	 * @returns
	 */
	public rebootDevice$(deviceId: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('rebootDevice()', 'Rebooting device', { deviceId });
		return this.deviceRebootMutationGQL.mutate({ rebootDeviceId: deviceId }).pipe(
			map((result) => {
				if (result.data?.rebootDevice.error) {
					this.logger.error('rebootDevice', 'error', result.data?.rebootDevice.error);
					throw new Error(result.data?.rebootDevice.error.message);
				}
				this.logger.trace('rebootDevice', 'Done', { deviceId });
				return { state: UpdateState.Done };
			}),
			catchError((error: Error) => {
				this.logger.error('rebootDevice', 'Error', { error });
				return of({ state: UpdateState.Error, error: error.message });
			})
		);
	}

	public updateFirmware$(updates: UpdateDeviceFirmwareInput[]): Observable<UpdateResponse<void, string>> {
		this.logger.debug('updateFirmware', 'Requesting updates', updates);

		this.lastFWUpdateTime.set(Date.now());

		return this.deviceUpdateFirmwareMutationGQL.mutate({ input: updates }).pipe(
			map((result) => {
				if ('error' in result) {
					throw result.error;
				}

				const { requestId, state: requestState } = result.data?.updateFirmware ?? {
					requestId: null,
					state: null
				};
				if (this.isFirmwareUpdateRequestFailed(requestState)) {
					this.logger.debug('updateFirmware', 'received GraphQL response with error(s)', { result });
					throw new Error(`Update failed with state ${requestState}: ${requestId}`);
				}

				this.logger.debug('updateFirmware', 'Request done', {
					requestId,
					requestState,
					updates
				});
				return { state: UpdateState.Done };
			}),
			catchError((error: Error) => {
				this.logger.error('updateFirmware', 'Error', { error });
				return of({ state: UpdateState.Error, error: error.message });
			})
		);
	}

	private isFirmwareUpdateRequestFailed(requestState: FirmwareUpdateRequestState | null | undefined): boolean {
		if (requestState === null || requestState === undefined) {
			return true;
		}

		const successStates = [
			FirmwareUpdateRequestState.Pending,
			FirmwareUpdateRequestState.InProgress,
			FirmwareUpdateRequestState.Successful
		];

		return !successStates.includes(requestState);
	}
}
