"use strict";

/* eslint-disable no-console -- @shure/api-logger does not yet work in web context */
var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestMessagePort = exports.electronApiKey = exports.WindowPortResponse = exports.requestPortResponseChannel = void 0;
const z = __importStar(require("zod"));
exports.requestPortResponseChannel = '@shure/application-apollo-link:port-to-main-process';
exports.WindowPortResponse = z.object({
  type: z.literal(exports.requestPortResponseChannel),
  requestId: z.number()
});
exports.electronApiKey = '@shure/application-apollo-link';
let portRequestId = 0;
async function requestMessagePort(serviceName) {
  if (window[exports.electronApiKey] === undefined) {
    throw new Error(`Required API ${exports.electronApiKey} has not been exposed via the preload script. Call installPreloadIpc from @shure/application-apollo-link-web`);
  }
  const requestId = ++portRequestId;
  const portPromise = new Promise((resolve, reject) => {
    function processMessage(event) {
      if (event.source !== window) {
        return;
      }
      const parsed = exports.WindowPortResponse.safeParse(event.data);
      if (!parsed.success || parsed.data.requestId !== requestId) {
        return;
      }
      window.removeEventListener('message', processMessage);
      if (event.ports.length !== 1) {
        reject(new Error('Response does not contain message port'));
        return;
      }
      resolve(event.ports[0]);
    }
    window.addEventListener('message', processMessage);
  });
  window[exports.electronApiKey].sendRequestPortToMainProcess(requestId, serviceName);
  const result = await portPromise;
  result.start();
  return result;
}
exports.requestMessagePort = requestMessagePort;
